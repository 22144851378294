<template>
  <!-- Navbar before login-->
  <nav v-if="!error404" id="participant-navbar"
    :class="{ 'navbar': true, 'navbar-expand-lg': true, 'navbar-light': true, 'navbar-brand-color': this.program, 'navbar-white': !this.program }">
    <div class="container header-mobile">
      <b-link class="navbar-brand d-flex align-items-center" @click="participantLandingPage">
        <img v-if="logoDisplay" class="program-logo mr-1" :src="logoDisplay" alt="Program homepage Logo">
        <h3 v-if="showProgramName" class="program-name primary-color mb-0 text-truncate"
          style="white-space: break-spaces;">
          {{ program.name }}
        </h3>
      </b-link>

      <button class="navbar-toggler menu-humburger" type="button" data-toggle="collapse"
        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <feather-icon icon="MenuIcon" size="20" />
        <i class="fa fa-bars fa-lg" aria-hidden="true" />
      </button>
      <div id="navbarSupportedContent" class="collapse navbar-collapse menu-mobile">
        <ul v-if="isParticipantPortal"
          class="navbar-nav ml-auto navbar-nav-custom sub-menu-destop d-flex align-items-center">
          <li v-if="program && program.website_url" class="nav-item">
            <a class="nav-link nav-link-icon secondary-color" :href="program.website_url" target="_blank">
              <feather-icon icon="GlobeIcon" size="16" class="align-text-top" />
              <span>Website</span>
            </a>
          </li>
          <li v-if="program && program.facebook_url" class="nav-item">
            <a class="nav-link nav-link-icon secondary-color" :href="program.facebook_url" target="_blank">
              <feather-icon role="presentation" icon="FacebookIcon" size="16" class="align-text-top" />
              <span>Facebook</span>
            </a>
          </li>
          <li v-if="program && program.twitter_url" class="nav-item">
            <a class="nav-link nav-link-icon secondary-color" :href="program.twitter_url" target="_blank">
              <feather-icon icon="TwitterIcon" size="16" class="align-text-top" />
              <span>Twitter</span>
            </a>
          </li>
          <li v-if="program && program.youtube_url" class="nav-item">
            <a class="nav-link nav-link-icon secondary-color" :href="program.youtube_url" target="_blank"
              aria-label="opens in new tab">
              <feather-icon role="presentation" icon="YoutubeIcon" size="16" class="align-text-top" />
              <span>Youtube</span>
            </a>
          </li>
          <li v-if="program && program.linkedin_url" class="nav-item">
            <a class="nav-link nav-link-icon secondary-color" :href="program.linkedin_url" target="_blank">
              <feather-icon icon="LinkedinIcon" size="16" class="align-text-top" />
              <span>LinkedIn</span>
            </a>
          </li>
          <li v-if="program && program.email" class="nav-item">
            <a class="nav-link nav-link-icon secondary-color" :href="`mailto:${program.email}`">
              <feather-icon icon="MailIcon" size="16" class="align-text-top" />
              <span>Email</span>
            </a>
          </li>
          <li v-if="program && program.recruitment_video_url" class="nav-item">
            <a class="nav-link nav-link-icon secondary-color" :href="program.recruitment_video_url" target="_blank">
              <feather-icon icon="GlobeIcon" size="16" class="align-text-top" />
              <span>Recruitment video</span>
            </a>
          </li>
          <li v-if="
            program &&
            (program.website_url ||
              program.facebook_url ||
              program.twitter_url ||
              program.youtube_url ||
              program.linkedin_url ||
              program.email ||
              program.recruitment_video_url)
          " class="nav-item nav-item-vertical-line">
            <a class="nav-link nav-link-vertical-line" />
          </li>
          <li v-if="program">
            <locale class="mr-2" :program-languages="program.languages" :active-languages="activeProgramLanguages"
              @select="selectedLocale" />
          </li>
          <li class="nav-item ml-3">
            <b-button v-if="isLoggedIn"
              :to="{ name: 'participant-home', params: { clientSlug: clientPath, programPath: $route.params.programPath } }"
              variant="primary">
              Program Portal
            </b-button>
            <b-button v-else
              :to="{ name: 'participant-login', params: { clientSlug: clientPath, programPath: $route.params.programPath } }"
              variant="primary">
              Log In
            </b-button>
          </li>
        </ul>

        <ul v-else class="navbar-nav ml-auto navbar-nav-custom sub-menu-destop d-flex align-items-center">
          <li class="nav-item ml-3">
            <b-button :to="{ name: 'auth-login' }" variant="primary">
              Log In
            </b-button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BButton,
  BLink
} from "bootstrap-vue";
import Locale from "@/layouts/components/app-navbar/components/Locale.vue";
import { $themeConfig } from '@themeConfig';
import { AOM_MAIN_DOMAIN, AOM_LOCALSTORAGE_SELECTED_LANGUAGE_KEY } from '@/constants/app';

export default {
  components: {
    BButton,
    BLink,
    Locale
  },

  data() {
    return {
      appLogoImage: $themeConfig.app.appLogoImage,
      sideImg: require("@/assets/images/logo/logo-h.png"),
    };
  },

  computed: {
    ...mapGetters('profile', ['isLoggedIn']),
    ...mapGetters('app', ['isParticipantPortal', 'currentProgram']),
    ...mapGetters('programs',['defaultProgram']),

    program() {
      return this.currentProgram || this.defaultProgram;
    },
    clientPath() {
      return this.currentProgram?.client?.path;
    },
    programPath() {
      return this.currentProgram?.path;
    },

    appLogo() {
      let logo = this.appLogoImage;
      if (this.program && this.program.program_logos) {
        const primaryLogo = this.program.program_logos.find(logo => logo.type_id === 1);

        if (primaryLogo) {
          logo = primaryLogo.url;
        }
      }
      return logo;
    },

    showProgramName() {
      return (
        this.program &&
        (this.isPrintApplicationPage || this.isParticipantPortal)
      );
    },

    error404() {

      return this.$route.name === "error-404";
    },

    activeProgramLanguages() {
      if (this.program && this.program.landing_pages) {
        return this.program.landing_pages.map(landingPage => landingPage.locale_id);
      }

      return null;
    },

    logoDisplay() {
      if (this.$route.name === "participant-login") {
        return '';
      }
      if (
        !this.isPrintApplicationPage && !this.isParticipantPortal
      ) {
        return this.sideImg;
      }
      return this.appLogo;
    },

    isPrintApplicationPage() {
      return this.$route.name == "print-application-matching-questions";
    }
  },

  methods: {
    selectedLocale(locale) {
      window.localStorage.setItem(AOM_LOCALSTORAGE_SELECTED_LANGUAGE_KEY, locale.locale);
      this.$store.commit('participants/SET_LANDING_PAGE_LOCALE_SELECTED', locale);
    },
    participantLandingPage() {
      const protocol = window.location.protocol;
      const clientSlug = this.$route.params.clientSlug || this.clientPath;
      const programPath = this.$route.params.programPath || this.programPath;
      if (programPath && clientSlug) {
        return window.location = `${protocol}//${clientSlug}.${AOM_MAIN_DOMAIN}/${programPath}`;
      }
    },
  }
};
</script>
<style scoped lang="scss">
.navbar-brand-color {
  background-color: var(--aom-color-primary);
}

.program-name {
  color: white !important;
}

.header-mobile {
  flex-wrap: nowrap;
}
</style>
