<template>
  <footer class="footer-section">
    <div class="wrap-container">
      <b-row>
        <b-col md="4" class="mb-1 flex">
          <b-row>
            <b-col sm="4" class="mb-1 flex">
              <a
                href="https://knowledgebase.aomapps.au/general-support"
                class="text-white"
                target="_blank"
              >
                Help & Support</a
              >
            </b-col>
            <b-col sm="4" class="mb-0 flex">
              <router-link
                class="text-white"
                :to="{
                  name: isParticipantPortal
                    ? 'participant-privacy'
                    : 'app-privacy',
                }"
              >
                Privacy Policy
              </router-link>
            </b-col>
          </b-row>
        </b-col>

        <b-col sm="4" class="text-center mb-0 copyright">
          <span>&copy; {{ currentYear }} Art of Mentoring</span>
        </b-col>

        <b-col
          sm="4"
          class="text-right d-flex flex-column-reverse flex-sm-column mb-0"
        >
          <p>
            <router-link
              class="text-white"
              :to="{
                name: isParticipantPortal ? 'participant-terms' : 'app-terms',
              }"
            >
              Terms of Acceptable Use
            </router-link>
          </p>
          <p>Platform v2</p>
        </b-col>
      </b-row>
    </div>
  </footer>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { mapGetters } from "vuex";

export default {
  name: "Footer",

  components: {
    BRow,
    BCol,
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },

  computed: {
    ...mapGetters("app", ["isParticipantPortal"]),
  },
};
</script>
